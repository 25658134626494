<template>
    <Component
        :is="tag"
        class="observer"
    >
        <slot />
    </Component>
</template>

<script>
// Store observers in global and allow adding multiple components to it
let observers = {};

export default {
    name: 'Observer',
    props: {
        // 'intersection'
        type: { type: String, required: true },
        tag: { type: String, default: 'span' },

        // Group multiple elements into the one observer for performance
        namespace: { type: String, required: true },

        // Options is a function so element properties are accessed
        // after the component has mounted.
        options: { type: Function, default: () => ({}) }
    },
    emits: ['update'],
    mounted() {
        // No need to create a new observer, just add this component to the one that
        // already exists
        if (observers[this.namespace]) {
            return this.observe();
        }

        // Store count and increment per component of the same namespace. That way we can
        // disconnect the observer if all components are unobserved.
        observers[this.namespace] = { count: 0 };

        // Create observer based on type
        const Observer = {
            intersection: IntersectionObserver,
            resize: ResizeObserver,
            mutation: MutationObserver
        }[this.type];
        observers[this.namespace].observer = new Observer((entries, observer) => {
            entries.forEach(entry => {
                entry.target.__component.$emit('update', { entry, entries, observer });
            });
        }, this.options());

        this.observe();
    },
    beforeUnmount() {
        this.unobserve();

        // No entries left, remove the observer and namespace
        if (observers[this.namespace] && observers[this.namespace].count < 1) {
            observers[this.namespace].observer.disconnect();
            delete observers[this.namespace];
        }
    },
    methods: {
        observe() {
            // Store reference to component on the DOM element so
            // we can access its $emit method in the observer callback
            this.$el.__component = this;
            if (!observers[this.namespace]) return;
            observers[this.namespace].observer.observe(this.$el);
            observers[this.namespace].count++;
        },
        unobserve() {
            delete this.$el.__component;
            if (!observers[this.namespace]) return;
            observers[this.namespace].observer.unobserve(this.$el);
            observers[this.namespace].count--;
        }
    }
};
</script>

<style lang="less">
.observer { display: block; }
</style>
